var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            type: "info",
            closable: "",
            title: "立即升级",
            size: "small",
          },
          on: {
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              "收到您的升级申请后，专属客服会第一时间致电于您，请放心接听。您也可拨打24小时客服电话：400-000-000。"
            ),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "default" },
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.hideModal } },
                [_vm._v("发送请求")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }